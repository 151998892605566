import { HTMLAttributes } from 'react'
import classNames from 'classnames'

const SkeletonBase = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames('relative overflow-hidden bg-gray-50', className)} {...props}>
      <div className="skeleton-animation absolute top-0 left-0 h-full w-full" />
    </div>
  )
}

export default SkeletonBase
