import { isServer } from '@/pages/_app'

const disabledMainScroll = () => {
  if (isServer) {
    return
  }
  document.body.style.overflow = 'hidden'
}

const ableMainScroll = () => {
  if (isServer) {
    return
  }
  document.body.style.overflow = 'unset'
}

export enum SafeAreaEnum {
  Top = '--safe-area-top',
  Left = '--safe-area-left',
  Right = '--safe-area-right',
  Bottom = '--safe-area-bottom'
}
export const getSafeArea = (type: SafeAreaEnum) =>
  parseFloat(getComputedStyle(document.documentElement).getPropertyValue(type))

export const StyleUtils = {
  ableMainScroll,
  disabledMainScroll,
  getSafeArea
}
