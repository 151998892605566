import { PropsWithChildren } from 'react'
import { motion } from 'framer-motion'
import { ComponentUtils } from '@/utils/componentUtils'

type MainProps = {
  className?: string
  background?: string
}

const MainBody = ({ className, children, background = 'bg-white' }: PropsWithChildren<MainProps>) => {
  return (
    <motion.main
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.3 }}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
      }}
      id="main-body"
      className={ComponentUtils.cn('h-full min-h-screen w-full max-w-body', background, className)}>
      <div id="modal" className="fixed bottom-0 z-dim" />
      <div id="bottom-sheet" className="fixed bottom-0 z-dim" />
      <div id="draggable-bottom-sheet" className="fixed bottom-0 z-subHeader" />
      <div id="toast" className="fixed bottom-0 z-toast" />
      <div id="loading" className="fixed bottom-0 z-loading" />
      {children}
    </motion.main>
  )
}

export default MainBody
