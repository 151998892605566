import { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import { ComponentUtils } from '@/utils/componentUtils'

type ButtonProps = {
  theme?: ButtonThemeEnum
  size?: ButtonSizeEnum
  full?: boolean
  active?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export enum ButtonThemeEnum {
  Outline = 'Outline',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Chip = 'Chip'
}

export enum ButtonSizeEnum {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  SquareExtraLarge = 'SquareExtraLarge',
  ExtraLarge = 'ExtraLarge'
}

const buttonSize: {
  [key in ButtonSizeEnum]: string
} = {
  [ButtonSizeEnum.Small]: 'px-12 h-28',
  [ButtonSizeEnum.Medium]: 'px-16 h-36',
  [ButtonSizeEnum.Large]: 'px-20 h-46',
  [ButtonSizeEnum.SquareExtraLarge]: 'px-20 h-57',
  [ButtonSizeEnum.ExtraLarge]: 'px-24 h-57'
}

/**
 * 버튼상태: css 속성자
 * normal: default
 * hover: hover
 * pressed: active
 * disabled: disabled
 *
 * 아래 상태는 따로 정의합니다.
 * active: selected
 */
const buttonThemeStyle: {
  [key in ButtonThemeEnum]: string
} = {
  [ButtonThemeEnum.Outline]:
    'rounded-xs bg-white border-1 border-gray-300 text-gray-700 hover:bg-gray-50 hover:text-gray-700 active:border-1 active:border-gray-300 active:bg-gray-200 disabled:border-1 disabled:border-gray-200 disabled:bg-white disabled:text-gray-200',
  [ButtonThemeEnum.Primary]:
    'rounded-xs bg-primary text-white hover:bg-primary-600 active:bg-primary-700 disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 disabled:hover:text-gray-300',
  [ButtonThemeEnum.Secondary]:
    'rounded-xs bg-secondary text-white hover:bg-secondary-700 active:bg-secondary-800 disabled:bg-gray-100 disabled:text-gray-300',
  [ButtonThemeEnum.Chip]:
    'rounded-md bg-white border-1 border-gray-300 text-gray-700 hover:bg-gray-50 hover:text-gray-700 active:border-1 active:border-gray-300 active:bg-gray-200 active:text-gray-700 disabled:bg-white disabled:border-1 disabled:border-gray-200 disabled:text-gray-200'
}

const buttonThemeActiveStyle: {
  [key in ButtonThemeEnum]: string
} = {
  [ButtonThemeEnum.Outline]:
    'rounded-xs border-gray-100 bg-gray-100 text-gray-700 border-1 disabled:border-1 disabled:border-gray-200 disabled:bg-white disabled:text-gray-200',
  [ButtonThemeEnum.Primary]: 'rounded-xs bg-primary-800 text-white disabled:bg-gray-100 disabled:text-gray-300',
  [ButtonThemeEnum.Secondary]: 'rounded-xs bg-secondary-900 text-white disabled:bg-gray-100 disabled:text-gray-300',
  [ButtonThemeEnum.Chip]:
    'border-gray-700 bg-gray-700 rounded-md border-1 text-white disabled:bg-white disabled:border-1 disabled:border-gray-200 disabled:text-gray-200'
}

/**
 * 디자인 시스템을 따르는 버튼 입니다.
 *
 * @param children
 * @param size: 버튼 사이즈
 * @param theme: 버튼 테마
 * @param full: w-full 속성추가
 * @param className: 유연한 스타일링 적용
 * @param props: 기본 버튼 props
 *
 * @link: https://www.figma.com/file/bnWIiHfgHgV5QyiVP5ek8t/Design-System?node-id=67-430&t=ddnrVVOgKEAvzbOd-0
 */
const Button = ({
  children,
  size = ButtonSizeEnum.Medium,
  theme = ButtonThemeEnum.Primary,
  full = false,
  className,
  active = false,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      className={ComponentUtils.cn(
        active ? buttonThemeActiveStyle[theme] : buttonThemeStyle[theme],
        buttonSize[size],
        full && 'w-full',
        className,
        'flex items-center justify-center gap-10 whitespace-nowrap'
      )}
      {...props}>
      {children}
    </button>
  )
}

export default Button
