import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { ComponentUtils } from '@/utils/componentUtils'

export enum HeaderTypeHeightEnum {
  Header = 'h-57',
  DeliveryTap = 'h-57',
  HeaderDeliveryTap = 'h-114',
  HeaderOrderDeliveryTap = 'h-103',
  None = 'h-0'
}

type BaseHeaderProps = {
  blankDivHeight?: HeaderTypeHeightEnum
  className?: string
}

const BaseHeader = ({
  children,
  blankDivHeight = HeaderTypeHeightEnum.Header,
  className
}: PropsWithChildren<BaseHeaderProps>) => {
  return (
    <>
      <div
        className={ComponentUtils.cn(
          'fixed top-0 z-header flex min-h-[env(safe-area-inset-top)] w-full max-w-body flex-col',
          className
        )}>
        {children}
      </div>
      <div className="flex flex-col">
        <div className="min-h-[env(safe-area-inset-top)] w-full max-w-body bg-transparent" />
        <div className={classNames('w-full max-w-body bg-transparent', blankDivHeight)} />
      </div>
    </>
  )
}

export default BaseHeader
